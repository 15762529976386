import { T } from "@/locales";
import moment from "moment";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    width: 60,
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    key: "material_name",
    width: 240,
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
    key: "material_number",
    width: 180,
  },
  {
    title: T("质检状态"),
    dataIndex: "status_display",
    width: 120,
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
    key: "batch_number",
    width: 180,
  },
  {
    title: T("盘点状态"),
    dataIndex: "is_completed",
    width: 160,
    ellipsis: true,
    customRender: (_, item) => (item.is_completed ? (item.is_adjust ? "调整库存" : "保持不变") : "待处理"),
  },
  {
    title: T("库位编号"),
    dataIndex: "location_number",
    key: "location_number",
    width: 180,
  },
  {
    title: T("入库日期"),
    dataIndex: "stock_in_date",
    key: "stock_in_date",
    width: 160,
  },
  {
    title: T("库龄"),
    dataIndex: "stock_age",
    key: "stock_age",
    width: 100,
    customRender: (_, item) => (item.stock_in_date ? moment().diff(moment(item.stock_in_date), "days") : ""),
  },
  {
    title: T("账面数量"),
    dataIndex: "book_quantity",
    key: "book_quantity",
    width: 120,
  },
  {
    title: T("实际数量"),
    dataIndex: "actual_quantity",
    key: "actual_quantity",
    width: 120,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    fixed: "right",
    width: 180,
    scopedSlots: { customRender: "action" },
  },
];
