<template>
  <div>
    <a-card :title="$t('损益单详情')">
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">{{ $t("返回") }}</a-button>
      <section id="printContent">
        <a-spin :spinning="orderLoading">
          <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
          <a-descriptions bordered>
            <a-descriptions-item :label="$t('损益单号')">
              {{ profitLossOrderItem.number }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('盘点单号')">
              {{ profitLossOrderItem.stock_check_order_number }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('仓库')">
              {{ profitLossOrderItem.warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('创建人')">
              {{ profitLossOrderItem.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('创建时间')">
              {{ profitLossOrderItem.create_time }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>
        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="profitLossMaterialItems"
            :loading="materialLoading"
            :pagination="false"
            :scroll="{ x: 1020 }"
          >
            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small" :disabled="true">
                <a-popconfirm :title="$t('确定保持不变吗?')" :disabled="item.is_completed" @confirm="remainsUnchanged(item)">
                  <a-button type="primary" :loading="remainsUnchangedLoading" :disabled="item.is_completed">{{
                    $t("保持不变")
                  }}</a-button>
                </a-popconfirm>
                <a-popconfirm :title="$t('确定调整库存吗?')" :disabled="item.is_completed" @confirm="adjustingInventory(item)">
                  <a-button type="primary" :loading="adjustingInventoryLoading" :disabled="item.is_completed">
                    {{ $t("调整库存") }}
                  </a-button>
                </a-popconfirm>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </section>
    </a-card>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import {
  profitLossOrderRetrieve,
  profitLossOrderMaterials,
  profitLossMaterialRemainsUnchanged,
  profitLossMaterialAdjustingInventory,
} from "@/api/stockCheck";
import { columns } from "./columns";

export default {
  data() {
    return {
      columns,
      orderLoading: false,
      materialLoading: false,
      profitLossOrder: undefined,
      profitLossOrderItem: {},
      profitLossMaterialItems: [],
      remainsUnchangedLoading: false,
      adjustingInventoryLoading: false,
    };
  },
  methods: {
    initData() {
      this.profitLossOrder = this.$route.query.id;
      this.orderLoading = true;
      document.getElementById("jsbarcode").html = "";
      profitLossOrderRetrieve({ id: this.profitLossOrder })
        .then((data) => {
          this.profitLossOrderItem = data;
          var jsbarcode = new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
        })
        .finally(() => {
          this.orderLoading = false;
        });

      this.materialLoading = true;
      profitLossOrderMaterials({ id: this.profitLossOrder })
        .then((data) => {
          this.profitLossMaterialItems = data;
          if (this.profitLossMaterialItems.length === 0) {
            this.$message.warn(this.$t("请先盘点!"));
          }
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    remainsUnchanged(item) {
      this.remainsUnchangedLoading = true;
      profitLossMaterialRemainsUnchanged({ id: item.id })
        .then((data) => {
          this.$message.success(this.$t("保持不变成功"));
          this.profitLossMaterialItems.splice(
            this.profitLossMaterialItems.findIndex((i) => i.id == item.id),
            1,
            data
          );
        })
        .finally(() => {
          this.remainsUnchangedLoading = false;
        });
    },
    adjustingInventory(item) {
      this.adjustingInventoryLoading = true;
      profitLossMaterialAdjustingInventory({ id: item.id })
        .then((data) => {
          this.$message.success(this.$t("调整库存成功"));
          this.profitLossMaterialItems.splice(
            this.profitLossMaterialItems.findIndex((i) => i.id == item.id),
            1,
            data
          );
        })
        .finally(() => {
          this.adjustingInventoryLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style>
.jsbarcode {
  float: right;
}
</style>
